* {
  box-sizing: border-box;
}

.header {
  background-image: url("./assets/images/header.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-shadow: 0 0 2px black;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}

.header-title {
  color: beige !important;
}

.nav-link {
  color: beige !important;
}

.nav-link:hover {
  color: gainsboro !important;
}

.selected {
  border-top: 1px beige solid;
  border-bottom: 1px beige solid;
}

.label h3 {
  padding: 5px 15px 0 15px;
  font-size: 24px;
}

.label p {
  padding: 0px 15px;
  font-size: 18px;
}

.project {
  position: relative;
  width: 50%;
}

.label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(40, 40, 40);
}

.project:hover .label {
  opacity: 0.9;
}

.label-data {
  position: absolute;
  top: 20px;
  left: 20px;
}

.label-data a {
  position: relative;
}

.label-data a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.label-data a:hover::before {
  transform: scaleX(1);
}

/* Image size fixes - std should fill to container width, hero has min width and about has width according to page size */
.image {
  width: 100%;
  height: 300px !important;
  object-fit: cover;
  object-position: top center;
}

.avatar {
  width: 300px;
}

/* end image size fixes */

.project {
  position: relative;
}
